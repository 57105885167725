import firebase from "firebase/app";
import axios from 'axios';
import "firebase/auth";
export default {
  state: {
    loggedInUser:
      localStorage.getItem("userInfo") != null
        ? JSON.parse(localStorage.getItem("userInfo"))
        : null,
    loading: false,
    error: null
  },
  getters: {
    loggedInUser: state => state.loggedInUser,
    loading: state => state.loading,
    error: state => state.error
  },  
  mutations: {
    setUser(state, data) {
      state.loggedInUser = data;
      state.loading = false;
      state.error = null;
    },
    setLogout(state) {
      state.loggedInUser = null;
      state.loading = false;
      state.error = null;
      // this.$router.go("/");
    },
    setLoading(state, data) {
      state.loading = data;
      state.error = null;
    },
    setError(state, data) {
      state.error = data;
      state.loggedInUser = null;
      state.loading = false;
    },
    clearError(state) {
      state.error = null;
    }
  },
  actions: {
    login({ commit }, data) {
      commit("clearError");
      commit("setLoading", true);
      var postData = new FormData();
      postData.append('username', data.email);
      postData.append('password', data.password);
      axios
        .post(
          data.url, postData
          // "https://gemas.penggiat-riset2.com/api/users/login", postData
        )
        .then((res) => {
          console.log(res.data.users);
          if (res.data.status) {
            commit("setUser", { uid: res.data.users.token });
            localStorage.setItem("userInfo", JSON.stringify(res.data.users.token));
          } else {
            localStorage.removeItem("userInfo");
          }
        })
        .catch((err) => {
          // handle error
          console.log(err);
          localStorage.removeItem("userInfo");
          commit("setError", error);
        });

      // firebase
      //   .auth()
      //   .signInWithEmailAndPassword(data.email, data.password)
      //   .then(user => {
      //     const newUser = { uid: user.user.uid };
      //     localStorage.setItem("userInfo", JSON.stringify(newUser));
      //     commit("setUser", { uid: user.user.uid });
      //     console.log("user");
      //   })
      //   .catch(function(error) {
      //     // Handle Errors here.
      //     // var errorCode = error.code;
      //     // var errorMessage = error.message;
      //     console.log(error);
      //     localStorage.removeItem("userInfo");
      //     commit("setError", error);
      //     // ...
      //   });
    },

    signUserUp({ commit }, data) {
      commit("setLoading", true);
      commit("clearError");
      firebase
        .auth()
        .createUserWithEmailAndPassword(data.email, data.password)
        .then(user => {
          commit("setLoading", false);

          const newUser = {
            uid: user.user.uid
          };
          console.log(newUser);
          localStorage.setItem("userInfo", JSON.stringify(newUser));
          commit("setUser", newUser);
        })
        .catch(error => {
          commit("setLoading", false);
          commit("setError", error);
          localStorage.removeItem("userInfo");
          console.log(error);
        });
    },
    signOut({ commit }) {
      firebase
        .auth()
        .signOut()
        .then(
          () => {
            localStorage.removeItem("userInfo");
            commit("setLogout");
          },
          _error => {}
        );
    }
  }
};
